import { createContext, Dispatch, SetStateAction } from "react";
import { LogoutProps, Provider, User } from "types/User";

interface UserContextProps {
    user: User;
    clearUserContext: () => void;
    updateUserContext: (user: User) => void;
    logout: (params: LogoutProps) => void;
    login: (email: string, password: string) => void;
    loginWithSSO: (provider: Provider, token: string, code?: string) => void;
}

export const UserContext = createContext<UserContextProps | null>(null);
