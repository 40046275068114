import {
    clearSessionStorage,
    clearUserAuth,
    storeUserAuth,
} from "helpers/auth/authHelpers";
import { USER } from "helpers/user/userHelpers";
import { useEffect, useReducer, useState } from "react";
import { userReducer } from "reducers/userReducer";
import { LogoutProps, Provider, User, UserActionType } from "types/User";
import Router from "next/router";
import { auth, user } from "api";
import { identify, reset, trackEvent } from "analytics";
import { AnalyticsEvent } from "types/Analytics";

export function useUser() {
    const initialUserState = {} as User;
    const [state, dispatch] = useReducer(userReducer, initialUserState);

    useEffect(() => {
        const localStorageUser = localStorage.getItem("user");

        if (localStorageUser!) {
            dispatch({
                type: UserActionType.SET_USER,
                payload: JSON.parse(localStorageUser),
            });
        }
    }, []);

    useEffect(() => {
        localStorage.setItem(USER, JSON.stringify(state));
    }, [state]);

    const clearUserContext = () => {
        dispatch({
            type: UserActionType.CLEAR_USER,
        });
    };

    const updateUserContext = (user: User) => {
        dispatch({
            type: UserActionType.SET_USER,
            payload: user,
        });
        trackEvent(AnalyticsEvent.UpdateUserDetails);
    };

    const login = async (email: string, password: string) => {
        try {
            const loggedInUser = await auth.login(email, password);
            storeUserAuth(loggedInUser);
            const userDetailsResponse = await user.getUserDetails();
            dispatch({
                type: UserActionType.SET_USER,
                payload: userDetailsResponse.userDetails,
            });
            identify(userDetailsResponse.userDetails.id);
        } catch (err) {
            throw err;
        }
    };

    const loginWithSSO = async (
        provider: Provider,
        token: string,
        code?: string
    ) => {
        try {
            const loggedInUser = await auth.loginWithSSO(provider, token, code);
            storeUserAuth(loggedInUser);
            const userDetailsResponse = await user.getUserDetails();
            dispatch({
                type: UserActionType.SET_USER,
                payload: userDetailsResponse.userDetails,
            });
            identify(userDetailsResponse.userDetails.id);
        } catch (err) {
            throw err;
        }
    };

    const logout = async ({ from }: LogoutProps) => {
        if (from) {
            await Router.push({ pathname: "/login", query: { from } });
        } else {
            Router.push("/login");
        }
        clearUserAuth();
        clearSessionStorage();
        reset();
        trackEvent(AnalyticsEvent.Logout);
        dispatch({
            type: UserActionType.CLEAR_USER,
        });
    };

    return {
        user: state,
        clearUserContext,
        updateUserContext,
        logout,
        login,
        loginWithSSO,
    };
}
