import { getRefreshToken } from "helpers/auth/authHelpers";
import {
    DeleteAccountResponse,
    ForgotPasswordResponse,
    LoginResponse,
    SignUpResponse,
} from "types/Response";
import request from "./request";

export function login(
    username: string,
    password: string
): Promise<LoginResponse> {
    return request<LoginResponse>({
        url: `/api/login`,
        method: "POST",
        data: {
            username,
            password,
        },
        skipAuthRefresh: true,
    });
}

export function loginWithSSO(
    provider: string,
    token: string,
    code?: string
): Promise<LoginResponse> {
    return request<LoginResponse>({
        url: `/api/login`,
        method: "POST",
        data: {
            token,
            code,
            provider,
        },
        skipAuthRefresh: true,
    });
}

export function signUp(
    name: string,
    email: string,
    password: string,
    orderUUID?: string,
): Promise<SignUpResponse> {
    const lowerCaseEmail = email.toLowerCase();
    return request<SignUpResponse>({
        url: `/api/register`,
        method: "POST",
        data: {
            name,
            username: lowerCaseEmail,
            email: lowerCaseEmail,
            password,
            password2: password,
            orderUUID,
        },
        skipAuthRefresh: true,
    });
}

export function signUpWithGoogle(
    token: string,
    orderUUID?: string
): Promise<SignUpResponse> {
    return request<SignUpResponse>({
        url: `/api/register/google`,
        method: "POST",
        data: {
            token,
            orderUUID,
        },
        skipAuthRefresh: true,
    });
}

export function signUpWithApple(
    token: string,
    code?: string,
    name?: string,
    orderUUID?: string,
): Promise<SignUpResponse> {
    return request<SignUpResponse>({
        url: `/api/register/apple`,
        method: "POST",
        data: {
            name,
            code,
            token,
            orderUUID,
        },
        skipAuthRefresh: true,
    });
}

export function refreshAuth(): Promise<LoginResponse> {
    return request<LoginResponse>({
        url: `/oauth/access_token`,
        method: "POST",
        params: {
            grant_type: "refresh_token",
            refresh_token: getRefreshToken(),
        },
    });
}

export function forgotPassword(email: string): Promise<ForgotPasswordResponse> {
    const lowerCaseEmail = email.toLowerCase();
    return request<ForgotPasswordResponse>({
        url: `/api/forgotPassword`,
        method: "POST",
        data: {
            username: lowerCaseEmail,
            isWebStore: true,
        },
    });
}

export function resetPassword(
    newPassword: string,
    confirmedNewPassword: string,
    token: string
): Promise<ForgotPasswordResponse> {
    return request<ForgotPasswordResponse>({
        url: `/api/reset`,
        method: "POST",
        data: {
            password: newPassword,
            password2: confirmedNewPassword,
        },
        params: {
            t: token,
        },
    });
}

export function deleteAccount(): Promise<DeleteAccountResponse> {
    return request<DeleteAccountResponse>({
        url: "/api/accounts",
        method: "DELETE",
    });
}
