import request from "./request";
import { Item, Merchant } from "types";
import { DataResponse, ItemResponse } from "types/Response";
import { Business, MerchantLocationSubdomainResponse } from "types/Merchant";

export function fetchMerchant(
    identifier: string
): Promise<DataResponse<Merchant>> {
    return request<DataResponse<Merchant>>({
        url: `/api/merchants/${identifier}`,
        method: "GET",
        readOnly: true,
    });
}

export function getMerchantLocationAndBusinessSubdomain(): Promise<
    DataResponse<MerchantLocationSubdomainResponse[]>
> {
    return request<DataResponse<MerchantLocationSubdomainResponse[]>>({
        url: "/api/merchants/subdomains",
        method: "GET",
        readOnly: true,
    });
}

export function getBusinessDetails(
    merchantIdentifier: string
): Promise<DataResponse<Business>> {
    return request<DataResponse<Business>>({
        url: `/api/businesses/${merchantIdentifier}/locations`,
        method: "GET",
        readOnly: true,
    });
}

export function getBusinessForLocation(
    subdomain: string
): Promise<DataResponse<{ subdomain: string }>> {
    return request<DataResponse<{ subdomain: string }>>({
        url: `/api/merchants/${subdomain}/business`,
        method: "GET",
        readOnly: true,
    });
}

export function getMerchantItems(
    merchantIdentifier: string
): Promise<DataResponse<ItemResponse>> {
    return request<DataResponse<ItemResponse>>({
        url: `/api/merchants/${merchantIdentifier}/items`,
        method: "GET",
        readOnly: true,
    });
}

export function getMerchantEvents(
    merchantIdentifier: string
): Promise<DataResponse<ItemResponse>> {
    return request<DataResponse<ItemResponse>>({
        url: `/api/merchants/${merchantIdentifier}/items?types=EVENT`,
        method: "GET",
        readOnly: true,
    });
}

export function getMerchantMemberships(
    merchantIdentifier: string
): Promise<DataResponse<ItemResponse>> {
    return request<DataResponse<ItemResponse>>({
        url: `/api/merchants/${merchantIdentifier}/items?types=MEMBERSHIP`,
        method: "GET",
        readOnly: true,
    });
}

export function getItemDetails(
    itemId: string,
    merchantId: string
): Promise<Item> {
    return request<Item>({
        url: `/api/offers/${itemId}/merchants/${merchantId}`,
        method: "GET",
        readOnly: true,
    });
}
