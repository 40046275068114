import { MantineThemeOverride, rem } from "@mantine/core";
import { customColors, monoColors } from "./colors";

export const customTheme: MantineThemeOverride = {
    colorScheme: "light",
    colors: customColors,
    primaryColor: "oznrPrimary",
    breakpoints: {
        lg: `${rem(1390)}`,
    },
    fontSizes: {
        xs: rem(12),
        sm: rem(14),
        md: rem(16),
        lg: rem(18),
        xl: rem(24),
    },
    spacing: {
        xs: rem(4),
        sm: rem(8),
        md: rem(16),
        lg: rem(24),
        xl: rem(32),
    },
    fontFamily: "Poppins",
    headings: {
        fontFamily: "Poppins",
        fontWeight: 600,
        sizes: {
            h1: {
                fontSize: rem(48),
            },
            h2: {
                fontSize: rem(36),
            },
            h3: {
                fontSize: rem(24),
            },
            h4: {
                fontSize: rem(20),
            },
            h5: {
                fontSize: rem(18),
            },
            h6: {
                fontSize: rem(16),
            },
        },
    },
    other: {
        monoColors: monoColors,
    },
    black: "#333333",
    components: {
        Avatar: {
            styles: (theme) => ({
                placeholder: {
                    backgroundColor: theme.colors.merchantPrimary[6],
                    color: theme.other.textColor ?? "#333",
                },
            }),
        },
        Badge: {
            styles: (theme) => ({
                root: {
                    textTransform: "none",
                    fontWeight: 600,
                },
            }),
        },
        Button: {
            variants: {
                filled: (theme) => ({
                    root: {
                        color: theme.other.textColor || "#fff",
                    },
                }),
            },
        },
        Drawer: {
            styles: (theme) => ({
                content: {
                    borderRadius: theme.radius.md,
                },
                transitionProps: {
                    transition: "slide-up",
                    duration: 500,
                    timingFunction: "ease",
                },
            }),
        },
        Modal: {
            styles: (theme) => ({
                inner: { boxSizing: "border-box" },
            }),
            defaultProps: {
                transitionProps: {
                    transition: "slide-up",
                    duration: 500,
                    timingFunction: "ease",
                },
                overlayProps: {
                    blur: 3,
                    opacity: 0.6,
                },
            },
        },

        Notification: {
            styles: (theme) => ({
                root: {
                    padding: theme.spacing.lg,
                },
                title: {
                    fontWeight: "bold",
                },
                icon: {
                    marginLeft: theme.spacing.md,
                },
            }),
        },
    },
};
