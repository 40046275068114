import { OrderHistoryResponse } from "types/Orders";
import {
    ActiveOrdersResponse,
    BooleanResponse,
    GuestOrderResponse,
    ReservationDetailsResponse,
} from "types/Response";
import request from "./request";

export function assignProxy(
    proxyName: string,
    proxyEmail: string,
    reservationId: string
): Promise<BooleanResponse> {
    return request<BooleanResponse>({
        url: `/api/proxy/assign`,
        method: "POST",
        data: {
            proxyName,
            proxyEmail,
            reservationId,
        },
    });
}

export function removeProxy(reservationId: string): Promise<BooleanResponse> {
    return request<BooleanResponse>({
        url: `/api/proxy/remove/${reservationId}`,
        method: "DELETE",
    });
}

export function removeEntry(reservationId: string): Promise<BooleanResponse> {
    return request<BooleanResponse>({
        url: `/api/reservations/${reservationId}`,
        method: "DELETE",
    });
}

export function getActiveOrders(
    merchantId: number
): Promise<ActiveOrdersResponse> {
    return request<ActiveOrdersResponse>({
        url: `/api/orders/merchants/${merchantId}/active`,
        method: "GET",
        readOnly: true,
    });
}

export function getOrderHistory(
    merchantId: number
): Promise<OrderHistoryResponse> {
    return request<OrderHistoryResponse>({
        url: `/api/orders/history?max=100`,
        method: "GET",
        readOnly: true,
        params: {
            merchantLocationId: merchantId,
        },
    });
}

export function getGuestOrder(
    uuid: string,
    email: string
): Promise<GuestOrderResponse> {
    return request<GuestOrderResponse>({
        url: `/api/guests/orders/${uuid}?email=${encodeURIComponent(email)}`,
        method: "GET",
        readOnly: true,
    });
}

export function getReservationDetails(
    reservationId: string
): Promise<ReservationDetailsResponse> {
    return request<ReservationDetailsResponse>({
        url: `/api/reservations/${reservationId}`,
        method: "GET",
        readOnly: true,
    });
}

export function getGuestReservationDetails(
    reservationUuid: string
): Promise<ReservationDetailsResponse> {
    return request<ReservationDetailsResponse>({
        url: `/api/guests/reservations/${reservationUuid}`,
        method: "GET",
        readOnly: true,
    });
}

export function claimReservation(
    reservationId: string
): Promise<BooleanResponse> {
    return request<BooleanResponse>({
        url: `/api/claim`,
        method: "POST",
        data: {
            reservationId,
        },
    });
}

export function claimProxyReservation(
    reservationUuid: string
): Promise<BooleanResponse> {
    return request<BooleanResponse>({
        url: `/api/proxy/claim`,
        method: "POST",
        data: {
            reservationUuid,
        },
    });
}
