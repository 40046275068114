import { MantineTheme } from "@mantine/core";

export const globalStyles = (theme: MantineTheme) => ({
    ".pointerCursor": {
        cursor: "pointer",
    },
    ".underline": {
        textDecoration: "underline",
    },
    ".formFields": {
        maxWidth: 450,
        minWidth: "40%",
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            minWidth: "100%",
        },
    },
    ".table": {
        "& thead tr th": {
            border: "none",
            fontSize: 16,
            fontWeight: 600,
            color: "#333333",
        },
        "& thead tr th:not(:first-of-type)": {
            textAlign: "center" as "center",
        },
        "& tbody tr td:not(:first-of-type)": {
            textAlign: "center" as "center",
        },
        "& tbody tr:last-of-type td": {
            borderBottom: `1px solid #CCC`,
        },
    },
    ".centerText": {
        textAlign: "center" as "center",
    },

    ".hiddenOnMobile": {
        [theme.fn.smallerThan("md")]: {
            display: "none",
        },
    },

    ".hiddenOnTablet": {
        [theme.fn.smallerThan("lg")]: {
            display: "none",
        },
    },

    ".hiddenOnDesktop": {
        [theme.fn.largerThan("md")]: {
            display: "none",
        },
    },
    ".fullWidth": {
        width: "100%",
    },

    ".extraModalXPadding": {
        paddingLeft: "40px",
        paddingRight: "40px",
    },

    ".primaryTextColor": {
        color: theme.other.isLight
            ? theme.other.textColor
            : theme.colors[theme.primaryColor][6],
    },

    ".primaryBackgroundColor": {
        background: theme.other.isLight
            ? theme.other.textColor
            : theme.colors[theme.primaryColor][6],
    },
});
