import { Membership, PendingMembershipResponse } from "types/Membership";
import { BooleanResponse, CheckoutCartResponse } from "types/Response";
import request from "./request";

export function cancelMembership(
    membershipId: number
): Promise<BooleanResponse> {
    return request<BooleanResponse>({
        url: `/api/memberships/${membershipId}/cancel`,
        method: "POST",
    });
}

export function claimMembership(
    uuid: string,
    paymentId?: string
): Promise<CheckoutCartResponse> {
    return request({
        url: "/api/memberships/claim",
        method: "POST",
        data: {
            uuid,
            sourceId: paymentId,
            allow3DSCardValidation: true,
        },
    });
}

export function getMemberships(
    merchantLocationId: number
): Promise<Membership[]> {
    return request<Membership[]>({
        url: `/api/memberships`,
        method: "GET",
        readOnly: true,
        params: {
            merchantLocationId,
        },
    });
}

export function getPendingMembership(
    uuid: string
): Promise<PendingMembershipResponse> {
    return request<PendingMembershipResponse>({
        url: `/api/memberships/pending/${uuid}`,
        method: "GET",
        readOnly: true,
    });
}
