import { AppProps } from "next/app";
import Head from "next/head";
import { Global, MantineProvider, MantineTheme } from "@mantine/core";
import { customTheme } from "styles/customTheme";
import { CartContext } from "context/cartContext";
import { useCart } from "hooks/useCart";
import { globalStyles } from "styles/Global.styles";
import { AppContext } from "context/appContext";
import { useAppContext } from "hooks/useAppContext";
import { UserContext } from "context/userContext";
import { useUser } from "hooks/useUser";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { FIVE_MIN_IN_MILLISECONDS } from "constants/numbers";
import { GoogleOAuthProvider } from "@react-oauth/google";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: FIVE_MIN_IN_MILLISECONDS,
        },
    },
});

export default function App(props: AppProps) {
    const { Component, pageProps } = props;
    const cart = useCart();
    const appContext = useAppContext();
    const userContext = useUser();

    return (
        <>
            <Head>
                <title>Oznr Marketplace</title>
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                />
                <script
                    async
                    type="text/javascript"
                    src={process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}
                ></script>
                <script async src="/__ENV.js" />
            </Head>
            <GoogleOAuthProvider
                clientId={process.env.NEXT_PUBLIC_GOOGLE_IDENTITY_KEY ?? ""}
            >
                <QueryClientProvider client={queryClient} contextSharing={true}>
                    <Global styles={globalStyles} />
                    <MantineProvider theme={customTheme}>
                        <AppContext.Provider value={appContext}>
                            <UserContext.Provider value={userContext}>
                                <CartContext.Provider value={cart}>
                                    <Component {...pageProps} />
                                </CartContext.Provider>
                            </UserContext.Provider>
                        </AppContext.Provider>
                    </MantineProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </GoogleOAuthProvider>
        </>
    );
}
