import { useEffect, useReducer } from "react";
import { cartReducer } from "reducers/cartReducer";
import { FulfillmentMethod, Item, Merchant } from "types";
import { Cart, CartActionType, CartByMerchantId } from "types/Cart";
import { size } from "lodash-es";
import { trackEvent } from "analytics";
import { AnalyticsEvent } from "types/Analytics";
import { ItemType, Variation } from "types/Item";

export function useCart() {
    const initialCartState = {} as CartByMerchantId;
    const [state, dispatch] = useReducer(cartReducer, initialCartState);

    useEffect(() => {
        const localStorageCart = localStorage.getItem("cart");

        if (localStorageCart!) {
            dispatch({
                type: CartActionType.INITIALIZE_CART,
                payload: JSON.parse(localStorageCart),
            });
        }
    }, []);

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(state));
    }, [state]);

    const addToCart = (
        qty: number,
        variation: Variation,
        merchant: Merchant,
        selectedFulfillment: FulfillmentMethod,
        type: ItemType,
        pickUpStartDate: Date,
        itemId: number,
        item: Item
    ) => {
        dispatch({
            type: CartActionType.ADD_TO_CART,
            payload: {
                qty: qty,
                variation: variation,
                merchant: merchant,
                selectedFulfillment: selectedFulfillment,
                type,
                pickUpStartDate,
                itemId,
                item,
            },
        });
        trackEvent(AnalyticsEvent.AddToCart, {
            item_id: itemId,
            item_name: variation.title,
            variation_id: variation.variant.name,
            currency: merchant.currencyCode,
            unit_price: (variation.price / 100).toFixed(2),
            value: ((variation.price * qty) / 100).toFixed(2),
            fulfillment_type: selectedFulfillment,
            quantity: qty,
            merchant_id: merchant.id,
            merchant_name: merchant.name,
        });
    };

    const removeItemFromCart = (
        variationId: string,
        merchantId: string,
        merchantCart: Cart,
        itemId: number
    ) => {
        trackEvent(AnalyticsEvent.RemoveFromCart, {
            item_id: itemId,
            item_name: merchantCart.items[variationId].variation.title,
            variation_id: variationId,
            value: (
                (merchantCart.items[variationId].variation.price *
                    merchantCart.items[variationId].qty) /
                100
            ).toFixed(2),
            quantity: merchantCart.items[variationId].qty,
        });
        if (size(merchantCart.items) == 1) {
            dispatch({ type: CartActionType.CLEAR_CART, payload: {} });
        } else {
            dispatch({
                type: CartActionType.REMOVE_ITEM_FROM_CART,
                payload: { merchantId, variationId, itemId },
            });
        }
    };

    const emptyCart = () => {
        dispatch({ type: CartActionType.CLEAR_CART, payload: {} });
    };

    return { cart: state, addToCart, removeItemFromCart, emptyCart };
}
