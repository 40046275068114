import { Tuple } from "@mantine/core";

export const customColors = {
    oznrPrimary: [
        "#EDEFFC", // outline and subtle hover, light background
        "#9EA4EB", // light hover
        "#CCD0F5",
        "#7881E3",
        "#515DDB",
        "#2B3AD4",
        "#4F5BDB", // default background
        "#2532B0", // default hover, outline variant
        "#111755",
        "#1A237E",
    ],
    oznrSecondary: [
        "#FFEFE5",
        "#FFD2B8",
        "#FFB58A",
        "#FF985C",
        "#FF7B2E",
        "#FD5D00",
        "#FF5E00",
        "#993800",
        "#662500",
        "#331300",
    ],
    success: [
        "#D4F7DF",
        "#C7EFD9",
        "#A4E5C1",
        "#80DBA9",
        "#5CD190",
        "#38C778",
        "#268651",
        "#227748",
        "#174F30",
        "#0B2818",
    ],
    failure: [
        "#DA1E28",
        "#FDBABD",
        "#FB8D93",
        "#FA6169",
        "#F8343F",
        "#F70815",
        "#9A050D",
        "#F9D2D4",
        "#630308",
        "#310204",
    ],
    gray: [
        "#F8F9FA",
        "#EDF0F3",
        "#E0E5EB",
        "#CACFD9",
        "#BEC2CE",
        "#989DAE",
        "#545863",
        "#545863",
        "#26292C",
        "#020202",
    ],
} as Record<string, Tuple<string, 10>>;

export const monoColors = {
    success_1: "#198038",
    gold: "#AF8903",
    lightBlue: "#00BFF0",
    white: "#FFFFFF",
    gray_1: "#F1F1F3",
    gray_2: "#9A9A9A",
    gray_3: "#434343",
    gray_4: "#333333",
    gray_5: "#E5E5E5",
    gray_6: "#FAFAFA",
    gray_7: "#CCC",
    gray_8: "#383C42",
    gray_9: "#f7f7f7",
};
