import { getItemQuantityObject } from "helpers/cart/cartHelpers";
import { Merchant, ShippingAddress } from "types";
import { Cart, CartTotals } from "types/Cart";
import { CheckoutCartResponse } from "types/Response";
import request from "./request";
import { getUntappdUserId } from "helpers/user/userHelpers";

export function calculateCart(
    merchant: Merchant,
    cart: Cart,
    shippingAddressId?: String | null,
    shippingAddress?: ShippingAddress | null
): Promise<CartTotals> {
    return request<CartTotals>({
        url: `/api/cart/calculate`,
        method: "POST",
        data: {
            merchantLocation: merchant.id.toString(),
            business: merchant.id.toString(),
            fulfillmentType: cart?.fulfillmentType.toString(),
            items: getItemQuantityObject(cart?.items),
            ...(shippingAddressId && {
                shippingAddress: shippingAddressId,
            }),
            ...(shippingAddress && {
                shippingAddress: shippingAddress,
            }),
        },
    });
}

export function checkoutCart(
    cart: Cart,
    merchantId: string,
    tipPercentage: number,
    salesTax: number,
    cartKey: string,
    paymentId?: string,
    shippingAddressId?: String | null
): Promise<CheckoutCartResponse> {
    const untappdUserId = getUntappdUserId();

    return request({
        url: "/api/checkout",
        method: "POST",
        data: {
            tipPercentage,
            business: merchantId,
            merchantLocation: merchantId,
            fulfillmentType: cart.fulfillmentType.toString(),
            items: getItemQuantityObject(cart.items),
            cartKey,
            estimatedSalesTax: salesTax,
            ...(paymentId && {
                sourceId: paymentId,
            }),
            ...(shippingAddressId && {
                shippingAddress: shippingAddressId,
            }),
            allow3DSCardValidation: true,
            ...(untappdUserId && {
                untappdUserId,
            }),
        },
    });
}

export function checkoutSubscription(
    cart: Cart,
    merchantId: string,
    tipPercentage: number,
    salesTax: number,
    cartKey: string,
    paymentId?: string
): Promise<CheckoutCartResponse> {
    const untappdUserId = getUntappdUserId();

    return request({
        url: "/api/checkout/subscribe",
        method: "POST",
        data: {
            tipPercentage,
            business: merchantId,
            merchantLocation: merchantId,
            fulfillmentType: cart.fulfillmentType.toString(),
            items: getItemQuantityObject(cart.items),
            cartKey,
            estimatedSalesTax: salesTax,
            ...(paymentId && {
                sourceId: paymentId,
            }),
            allow3DSCardValidation: true,
            ...(untappdUserId && {
                untappdUserId,
            }),
        },
    });
}

export function checkoutGuestCart(
    name: string,
    email: string,
    cart: Cart,
    merchantId: string,
    tipPercentage: number,
    salesTax: number,
    cartKey: string,
    paymentId?: string,
    shippingAddress?: ShippingAddress | null
): Promise<CheckoutCartResponse> {
    const untappdUserId = getUntappdUserId();

    return request({
        url: "/api/checkout/guest",
        method: "POST",
        data: {
            email,
            name,
            tipPercentage,
            business: merchantId,
            merchantLocation: merchantId,
            fulfillmentType: cart.fulfillmentType.toString(),
            items: getItemQuantityObject(cart.items),
            cartKey,
            estimatedSalesTax: salesTax,
            ...(paymentId && {
                sourceId: paymentId,
            }),
            ...(shippingAddress && {
                shippingAddress: shippingAddress,
            }),
            allow3DSCardValidation: true,
            ...(untappdUserId && {
                untappdUserId,
            }),
        },
    });
}

export function completeVerifiedReservation(
    paymentIntentId: string,
    completedSuccessfully: boolean
): Promise<CheckoutCartResponse> {
    return request({
        url: "/api/checkout/paymentAuthenticationCompleted",
        method: "POST",
        data: {
            paymentIntentId,
            completedSuccessfully,
        },
    });
}

export function enterRandomDraw(
    itemId: number,
    merchantId: number,
    sourceId: string
): Promise<CheckoutCartResponse> {
    const untappdUserId = getUntappdUserId();

    return request({
        url: "/api/lottery/enter",
        method: "POST",
        data: {
            sourceId,
            merchantLocation: merchantId,
            offer: itemId,
            quantity: 1,
            ...(untappdUserId && {
                untappdUserId,
            }),
        },
    });
}
