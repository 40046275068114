import { User, UserAction, UserActionType } from "types/User";

export function userReducer(state: User, action: UserAction): User {
    switch (action.type) {
        case UserActionType.SET_USER:
            return action.payload;
        case UserActionType.CLEAR_USER:
            return {} as User;
    }
}
