export interface Subscription {
    id: number;
    businessId: number;
    active: boolean;
    blacklisted: boolean;
    canEmail: boolean;
    canSms: boolean;
    canPush: boolean;
    createDate: Date;
    updateDate: Date;
}

export interface User {
    id: number;
    name: string;
    email: string;
    phoneNumber: string;
    last4: string;
    cardBrand: string;
    paymentMethodId: string;
}

export enum UserActionType {
    SET_USER = "UPDATE_USER",
    CLEAR_USER = "CLEAR_USER",
}

export interface LogoutProps {
    from?: string;
}

export type UserAction =
    | {
          type: UserActionType.SET_USER;
          payload: {
              name: string;
              id: number;
              email: string;
              phoneNumber: string;
              last4: string;
              cardBrand: string;
              paymentMethodId: string;
          };
      }
    | {
          type: UserActionType.CLEAR_USER;
      };

export enum Provider {
    APPLE = "apple",
    GOOGLE = "google",
}
