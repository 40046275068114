import { FulfillmentMethod } from "types";
import { Cart, CartLineItem, CartLineItemById, ItemQuantity } from "types/Cart";
import { Frequency, Item, Variation } from "types/Item";

export function isItemInCart(
    itemId: number,
    cartLineItems: CartLineItemById
): boolean {
    return Object.values(cartLineItems).find(
        (cartLineItem) => cartLineItem.variation.id === itemId
    )
        ? true
        : false;
}

function convertFulfillmentMethod(
    fulfillmentMethod: FulfillmentMethod
): FulfillmentMethod {
    return fulfillmentMethod === FulfillmentMethod.DIGITAL
        ? FulfillmentMethod.PICKUP
        : fulfillmentMethod;
}

export function calculateCartSubtotal(cartLineItems: CartLineItemById): number {
    return Object.values(cartLineItems).reduce<number>(function (
        total,
        cartLineItem: CartLineItem
    ) {
        return total + cartLineItem.qty * cartLineItem.variation.price;
    },
    0);
}

export function getItemQuantityObject(cartLineItems: CartLineItemById) {
    return Object.values(cartLineItems).reduce(function (
        itemQuantityObject,
        cartLineItem: CartLineItem
    ) {
        return {
            ...itemQuantityObject,
            [cartLineItem.variation.id]: cartLineItem.qty,
        };
    },
    {} as ItemQuantity);
}

export function canAddItemToCart(
    selectedFulfillment: FulfillmentMethod,
    item: Item,
    variation: Variation,
    cart?: Cart
) {
    if (!cart) {
        return true;
    }
    if (isItemInCart(variation.id, cart.items)) {
        return true;
    }
    if (subscriptionInCart(cart)) {
        return false;
    }
    if (!subscriptionInCart(cart) && item?.frequency != Frequency.ONCE) {
        return false;
    }
    return fulfillmentCheck(selectedFulfillment, cart?.fulfillmentType);
}

function fulfillmentCheck(
    selectedFulfillment: FulfillmentMethod,
    cartFulfillmentMethod?: FulfillmentMethod
): boolean {
    return (
        cartFulfillmentMethod === undefined ||
        convertFulfillmentMethod(selectedFulfillment) ===
            convertFulfillmentMethod(cartFulfillmentMethod)
    );
}

function subscriptionInCart(cart: Cart): boolean {
    return Object.values(cart.items).find(
        (cartLineItem) => cartLineItem.item.frequency != Frequency.ONCE
    )
        ? true
        : false;
}

export function getEarliestPickUpCartLineItem(cartLineItems: CartLineItemById) {
    return Object.values(cartLineItems).reduce(function (
        cartLineItemA: CartLineItem,
        cartLineItemB: CartLineItem
    ) {
        if (cartLineItemA.pickUpStartDate < cartLineItemB.pickUpStartDate) {
            return cartLineItemA;
        } else {
            return cartLineItemB;
        }
    });
}
