// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT != "local") {
    Sentry.init({
        dsn:
            SENTRY_DSN ||
            "https://12070948f38047b4b7c2fda1e4f0f362@o124181.ingest.sentry.io/6150207",
        tracesSampleRate: 0.2,
        environment: process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT,
    });
}
