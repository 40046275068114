import { auth } from "api";
import {
    containsLowerCase,
    containsNumber,
    containsUpperCase,
} from "helpers/string/stringHelpers";
import { removeSelectedShippingAddress, USER } from "helpers/user/userHelpers";
import { LoginResponse } from "types/Response";
import { User } from "types/User";
import { z } from "zod";

const ACCESS_TOKEN_KEY = "mMIF0MUaP";
const REFRESH_TOKEN_KEY = "iaTpnbIu3";

export function storeUserAuth(user: LoginResponse) {
    localStorage.setItem(ACCESS_TOKEN_KEY, user.access_token);
    localStorage.setItem(REFRESH_TOKEN_KEY, user.refresh_token);
}

export function storeUser(user: User) {
    localStorage.setItem(USER, JSON.stringify(user));
}

export function getAccessToken() {
    return isBrowser() ? localStorage.getItem(ACCESS_TOKEN_KEY) : null;
}

export function clearUserAuth() {
    if (isBrowser()) {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);
        localStorage.removeItem(USER);
    }
}

export function clearSessionStorage() {
    if (isBrowser()) {
        removeSelectedShippingAddress();
        sessionStorage.removeItem("cartResponse");
    }
}

export function isLoggedIn() {
    return isBrowser() ? !!localStorage.getItem(ACCESS_TOKEN_KEY) : false;
}

export function isBrowser(): boolean {
    return typeof window !== "undefined";
}

export function getRefreshToken() {
    return isBrowser() ? localStorage.getItem(REFRESH_TOKEN_KEY) : null;
}

export const passwordSchema = z
    .string()
    .min(8, { message: "Password must be 8 characters long" })
    .superRefine((val, ctx) => {
        if (!containsNumber(val)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Must contain a number.`,
            });
        }
        if (!containsLowerCase(val)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Must contain a lowercase character.`,
            });
        }

        if (!containsUpperCase(val)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Must contain an uppercase character.`,
            });
        }
    });
