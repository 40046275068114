import { CurrencyCode } from "./CurrencyCode";
import { Category } from "./Merchant";

export enum FulfillmentMethod {
    PICKUP = "PICKUP",
    SHIPPING = "SHIPPING",
    DIGITAL = "DIGITAL",
}

export enum ItemType {
    EVENT = "EVENT",
    PRODUCT = "PRODUCT",
    MEMBERSHIP = "MEMBERSHIP",
    BEER = "BEER",
    SPIRITS = "SPIRITS",
    CEREAL_MALT_BEVERAGE = "CEREAL_MALT_BEVERAGE",
    WINE = "WINE",
    SPARKLING_WINE = "SPARKLING_WINE",
    FORTIFIED_WINE = "FORTIFIED_WINE",
    HARD_CIDER = "HARD_CIDER",
    MIXED_ALCOHOLIC_BEVERAGE = "MIXED_ALCOHOLIC_BEVERAGE",
    CLOTHING = "CLOTHING",
    FOOD = "FOOD",
    GLASSWARE = "GLASSWARE",
}

export const AlcoholicTypes = [
    ItemType.BEER,
    ItemType.CEREAL_MALT_BEVERAGE,
    ItemType.FORTIFIED_WINE,
    ItemType.HARD_CIDER,
    ItemType.MIXED_ALCOHOLIC_BEVERAGE,
    ItemType.SPARKLING_WINE,
    ItemType.SPIRITS,
    ItemType.WINE,
];

export enum VisibilityType {
    PUBLIC = "PUBLIC",
    MEMBERSHIP_ONLY = "MEMBERSHIP_ONLY",
}

export enum ItemTagStatus {
    COMING_SOON = "COMING_SOON",
    NEW = "NEW",
    SOLD_OUT = "SOLD_OUT",
    CLOSED = "CLOSED",
    MEMBERS_ONLY = "MEMBERS_ONLY",
    EARLY_ACCESS = "EARLY_ACCESS",
    RANDOM_DRAW = "RANDOM_DRAW",
    NONE = "NONE",
}

export enum ShippingRestriction {
    SHIPPABLE_STATES = "SHIPPABLE_STATES",
    NONE = "NONE",
}

export interface Item {
    id: number;
    dateCreated: Date;
    saleEndDate: Date;
    hasRandomDrawRun: Boolean;
    active: Boolean;
    instructions: string;
    isSharedInventory: Boolean;
    maxQuantityAllowed: number;
    allowEarlyAccess: Boolean;
    isLottery: Boolean;
    earlyAccessOffset: Number;
    allowPresale: Boolean;
    imageUrl: string;
    expireDate: Date;
    selectionStartDate: Date;
    longDescription: string;
    fulfillmentTypes: FulfillmentMethod[];
    totalQuantity: Number;
    shippingUnitFlatRate: Number;
    shippingRestriction: ShippingRestriction;
    pickUpStartDate: Date;
    allowProxy: Boolean;
    price: number;
    lastUpdated: Date;
    allowRepurchase: boolean;
    startDate: Date;
    title: string;
    allowTransfer: Boolean;
    allowPickup: Boolean;
    type: ItemType;
    visibilityType: VisibilityType;
    subtitle: string;
    businessId: Number;
    salesTaxAmount: Number;
    quantityRemaining: Number;
    transactionFee: Number;
    abv: number;
    unitFee: Number;
    volume: Number;
    volumeUnits: string;
    currencyCode: CurrencyCode;
    itemDetails: MembershipDetails;
    variations: Variation[];
    variantGroup: VariantGroup;
    category: Category;
    frequency: Frequency;
}

export interface MembershipDetails {
    membershipBenefits: string;
    membershipFAQs: string;
    termsAndConditionsLink: string;
    contactEmail: string;
}

export interface Variation {
    abv: number;
    active: boolean;
    id: number;
    imageUrl: string;
    longDescription: string;
    maxQuantityAllowed: number;
    price: number;
    quantityRemaining: number;
    subtitle: string;
    title: string;
    variant: Variant;
    volume: number;
    volumeUnits: string;
    weight?: number;
    weightUnits?: string;
}

export interface Variant {
    id: number;
    name: string;
    displayOrder: number;
}

export interface VariantGroup {
    id: number;
    name: string;
    displayName: string;
}

export enum Frequency {
    MONTHLY = "MONTHLY",
    QUARTERLY = "QUARTERLY",
    ANNUALLY = "ANNUALLY",
    ONCE = "ONCE",
}
