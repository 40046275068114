import { IS_OVER_21_LOCAL_STORAGE, SEA_SALT } from "constants/strings";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import crypto from "crypto";

export function useAppContext() {
    const [cartDrawerOpened, setCartDrawerOpened] = useState(false);
    const [isOver21, setIsOver21] = useState(false);
    const router = useRouter();
    const yo = router.query.yo;
    const ts = parseInt(router.query.ts as string, 10);
    const utcid = router.query.utcid;
    const receivedHash = router.query.hash;

    function isValidQueryString() {
        const salt = SEA_SALT;
        const currentTime = Math.floor(Date.now() / 1000);
        const maxAllowedTime = 24 * 60 * 60; // 24 hours in seconds
        const clockSkew = 5 * 60; // 5 minutes in seconds

        if (ts > currentTime + clockSkew || currentTime - ts > maxAllowedTime) {
            return false;
        }

        const queryParamsWithoutHash = `yo=${yo}&utcid=${utcid}&ts=${ts}`;
        const expectedHash = crypto
            .createHash("sha1")
            .update(queryParamsWithoutHash + salt)
            .digest("hex");
        return receivedHash === expectedHash;
    }

    useEffect(() => {
        const isOver21LocalStorageItem = localStorage.getItem(
            `${IS_OVER_21_LOCAL_STORAGE}_${router.query.merchantLocation}`
        );

        if (isOver21LocalStorageItem!) {
            setIsOver21(true);
            return;
        }

        if (isValidQueryString()) {
            setIsOver21(true);
            localStorage.setItem(
                `${IS_OVER_21_LOCAL_STORAGE}_${router.query.merchantLocation}`,
                "true"
            );
        }
    }, [router.query.ts]);

    return { cartDrawerOpened, setCartDrawerOpened, isOver21, setIsOver21 };
}
