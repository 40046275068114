import { identify } from "analytics";
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {
    clearUserAuth,
    getAccessToken,
    storeUserAuth,
} from "helpers/auth/authHelpers";
import { getUser } from "helpers/user/userHelpers";
import Router from "next/router";
import { refreshAuth } from "./auth";

interface RequestConfig extends AxiosRequestConfig {
    readOnly?: boolean;
    skipAuthRefresh?: boolean;
}

const defaultHeaders: AxiosRequestHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "App-Type": "Web",
    "App-Version": process.env.NEXT_PUBLIC_APP_VERSION || "",
    "Accept-Version": "v3",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
};

export const http = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: defaultHeaders,
    timeout: 60000,
});

http.interceptors.request.use(function (config: RequestConfig) {
    if (config.headers && !!getAccessToken()) {
        config.headers["Authorization"] = `Bearer ${getAccessToken()}`;
    }

    if (config.headers && config.readOnly) {
        config.headers["Oznr-read-only"] = "true";
    }

    return config;
});

async function refreshAuthLogic(failedRequest: any) {
    try {
        const loggedInResponse = await refreshAuth();
        storeUserAuth(loggedInResponse);
        const user = getUser();
        if (user) {
            identify(user.id);
        }
        failedRequest.response.config.headers[
            "Authorization"
        ] = `Bearer ${getAccessToken()}`;
        return Promise.resolve();
    } catch (error) {
        clearUserAuth();
        const path = Router.asPath;
        Router.push({ pathname: "/login", query: { from: path } });
        return Promise.reject();
    }
}

createAuthRefreshInterceptor(http, refreshAuthLogic);

function request<DataType>(requestConfig: RequestConfig): Promise<DataType> {
    return http.request(requestConfig).then((resp) => {
        return resp.data;
    });
}

export default request;
