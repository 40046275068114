import { createContext } from "react";
import { FulfillmentMethod, Item, Merchant } from "types";
import { Cart, CartByMerchantId } from "types/Cart";
import { ItemType, Variation } from "types/Item";

interface CartContextProps {
    cart: CartByMerchantId;
    addToCart: (
        quantity: number,
        variation: Variation,
        merchant: Merchant,
        selectedFulfillment: FulfillmentMethod,
        type: ItemType,
        pickUpStartDate: Date,
        itemId: number,
        item: Item
    ) => void;
    removeItemFromCart: (
        variationId: string,
        merchantId: string,
        merchantCart: Cart,
        itemId: number
    ) => void;
    emptyCart: () => void;
}

export const CartContext = createContext<CartContextProps | null>(null);
