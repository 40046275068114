import { isBrowser } from "helpers/auth/authHelpers";
import { ShippingAddress } from "types";
import { User } from "types/User";
import { UNTAPPD_USER_ID_LOCAL_STORAGE } from "constants/strings";

export const USER = "user";

export function getUser(): User | null {
    const user = isBrowser() ? localStorage.getItem(USER) : null;
    if (user) {
        return JSON.parse(user);
    } else return null;
}

const SHIPPING_ADDRESS_KEY = "shippingAddress";

export function formatUserInitials(name?: string) {
    if (name && name != "") {
        let nameArray = name.split(" ");
        return `${nameArray[0][0]}${
            nameArray.length > 1 ? nameArray[nameArray.length - 1][0] : ""
        }`;
    } else {
        return "OZ";
    }
}

export function storeSelectedShippingAddress(shippingAddress: ShippingAddress) {
    sessionStorage.setItem(
        SHIPPING_ADDRESS_KEY,
        JSON.stringify(shippingAddress)
    );
}

export function getSelectedShippingAddress(): ShippingAddress | null {
    return sessionStorage.getItem(SHIPPING_ADDRESS_KEY)
        ? JSON.parse(sessionStorage.getItem(SHIPPING_ADDRESS_KEY) || "{}")
        : null;
}

export function removeSelectedShippingAddress() {
    return sessionStorage.removeItem(SHIPPING_ADDRESS_KEY);
}

export function getUntappdUserId(): string | null {
    if (!isBrowser()) {
        return null;
    }
    return localStorage.getItem(UNTAPPD_USER_ID_LOCAL_STORAGE);
}

export function storeUntappdUserId(untappdUserId: string) {
    localStorage.setItem(UNTAPPD_USER_ID_LOCAL_STORAGE, untappdUserId);
}
