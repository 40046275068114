import { createContext, Dispatch, SetStateAction } from "react";

interface AppContextProps {
    cartDrawerOpened: boolean;
    setCartDrawerOpened: Dispatch<SetStateAction<boolean>>;
    isOver21: boolean;
    setIsOver21: Dispatch<SetStateAction<boolean>>;
}

export const AppContext = createContext<AppContextProps>({
    cartDrawerOpened: false,
    setCartDrawerOpened: () => {},
    isOver21: false,
    setIsOver21: () => {},
});
