import { CartAction, CartActionType, CartByMerchantId } from "types/Cart";

export function cartReducer(state: CartByMerchantId, action: CartAction) {
    let currentCartItems;
    switch (action.type) {
        case CartActionType.INITIALIZE_CART:
            return action.payload;
        case CartActionType.ADD_TO_CART:
            const {
                variation,
                qty,
                merchant,
                selectedFulfillment,
                type,
                itemId,
                item,
            } = action.payload;
            if (state[merchant.id.toString()]) {
                currentCartItems = state[merchant.id].items;
            }
            return {
                ...state,
                [merchant.id]: {
                    fulfillmentType: selectedFulfillment,
                    items: {
                        ...currentCartItems,
                        [variation.id]: {
                            qty: qty,
                            variation: variation,
                            type,
                            itemId,
                            item,
                        },
                    },
                },
            };
        case CartActionType.REMOVE_ITEM_FROM_CART:
            const { merchantId, variationId } = action.payload;
            currentCartItems = state[merchantId].items;
            delete currentCartItems[variationId];
            let currentFulfillment = state[merchantId].fulfillmentType;
            return {
                ...state,
                [merchantId]: {
                    fulfillmentType: currentFulfillment,
                    items: {
                        ...currentCartItems,
                    },
                },
            };
        case CartActionType.CLEAR_CART:
            return action.payload;
    }
}
