import { Analytics } from "firebase/analytics";

export enum AnalyticsEvent {
    ViewItemList = "view_item_list",
    ViewItem = "view_item",
    ViewEnterDraw = "view_enter_draw",
    ViewCheckout = "view_checkout",
    ViewCheckoutSuccess = "view_checkout_success",
    ViewGuestCheckout = "view_guest_checkout",
    ViewGuestCheckoutSuccess = "view_guest_checkout_success",
    ViewSignUp = "view_sign_up",
    ViewLogin = "view_login",
    ViewAccount = "view_account",
    ViewFAQs = "view_faqs",
    ViewHelp = "view_help",
    ViewCart = "view_cart",
    ViewPaymentList = "view_payment_list",
    ViewShippingAddressList = "view_shipping_address_list",
    ViewMerchantOrders = "view_merchant_orders",
    ViewOrderHistory = "view_order_history",
    ViewOrder = "view_order",
    ViewActiveOrderItem = "view_active_order_item",
    ViewAddProxy = "view_add_proxy",
    Login = "login",
    GoogleLogin = "google_login",
    SignUp = "sign_up",
    Logout = "logout",
    ForgotPassword = "forgot_password",
    ResetPassword = "reset_password",
    UpdateUserDetails = "update_user_details",
    AddToCart = "add_to_cart",
    AddEntry = "add_entry",
    RemoveFromCart = "remove_from_cart",
    RemoveEntry = "remove_entry",
    SelectPayment = "select_payment",
    SavePayment = "save_payment",
    SelectShippingAddress = "select_shipping_address",
    SaveShippingAddress = "save_shipping_address",
    Purchase = "purchase",
    ClaimOrder = "claim_order",
    AddProxy = "add_proxy",
    RemoveProxy = "remove_proxy",
    CancelTransfer = "cancel_transfer",
}

export interface TrackingOptions {
    [key: string]: string | number;
}

declare global {
    interface Window {
        analytics: Analytics;
    }
}
