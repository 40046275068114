export function capitalize(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const CONTAINS_NUMBER_REGEXP = /.*[0-9]/;
export const CONTAINS_UPPER_CASE_REGEXP = /.*[A-Z]/;
export const CONTAINS_LOWER_CASE_REGEXP = /.*[a-z]/;

export const containsNumber = (string: string) =>
    string.match(CONTAINS_NUMBER_REGEXP);

export const containsUpperCase = (string: string) =>
    string.match(CONTAINS_UPPER_CASE_REGEXP);

export const containsLowerCase = (string: string) =>
    string.match(CONTAINS_LOWER_CASE_REGEXP);

//same random id function that mantine uses in the useId hook
export const randomId = () => {
    return `oznr-${Math.random().toString(36).slice(2, 11)}`;
};
