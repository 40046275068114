import { FulfillmentMethod, Item, ItemType, Variant, Variation } from "./Item";
import { Merchant } from "./Merchant";

export enum CartActionType {
    ADD_TO_CART = "ADD_TO_CART",
    INITIALIZE_CART = "INITIALIZE_CART",
    CLEAR_CART = "CLEAR_CART",
    REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART",
}

export type CartAction =
    | {
          type: CartActionType.ADD_TO_CART;
          payload: {
              qty: number;
              variation: Variation;
              merchant: Merchant;
              selectedFulfillment: FulfillmentMethod;
              type: ItemType;
              pickUpStartDate: Date;
              itemId: number;
              item: Item;
          };
      }
    | {
          type: CartActionType.REMOVE_ITEM_FROM_CART;
          payload: {
              variationId: string;
              merchantId: string;
              itemId: number;
          };
      }
    | {
          type: CartActionType.INITIALIZE_CART;
          payload: CartByMerchantId;
      }
    | {
          type: CartActionType.CLEAR_CART;
          payload: {};
      };

export interface CartByMerchantId {
    [merchantId: string]: Cart;
}

export interface Cart {
    fulfillmentType: FulfillmentMethod;
    items: CartLineItemById;
    shippingAddressId?: number;
}

export interface CartLineItemById {
    [itemId: string]: {
        variation: Variation;
        qty: number;
        type: ItemType;
        pickUpStartDate: Date;
        itemId: number;
        item: Item;
    };
}

export interface CartLineItem {
    variation: Variation;
    qty: number;
    type: ItemType;
    pickUpStartDate: Date;
    itemId: number;
    item: Item;
}

export interface ItemQuantity {
    [itemId: string]: number;
}

export interface CartTotals {
    cartTotal: number;
    totalSalesTax: number;
    totalConsumerFee: number;
    totalShippingFee: number;
    freeShippingTierThreshold: number;
}
