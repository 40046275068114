import { PaymentMethod, ShippingAddress } from "types";
import {
    DataResponse,
    EditUserDetailsParams,
    SetUpPaymentKeyResponse,
    UserDetailsResponse,
} from "types/Response";
import { User } from "types/User";
import request from "./request";

export function fetchSetUpPaymentKey(): Promise<SetUpPaymentKeyResponse> {
    return request<SetUpPaymentKeyResponse>({
        url: "/api/payments/setUpPaymentKey",
        method: "GET",
        readOnly: true,
    });
}

export function getUserDetails(): Promise<UserDetailsResponse> {
    return request<UserDetailsResponse>({
        url: `/api/userDetails`,
        method: "GET",
    });
}

export function updateUserDetails({
    name,
    phoneNumber,
    email,
}: EditUserDetailsParams): Promise<User> {
    return request<User>({
        url: "/api/userDetails",
        method: "PUT",
        data: {
            name,
            phoneNumber,
            email,
        },
    });
}

export function setDefaultPaymentMethod(
    defaultPayment: PaymentMethod
): Promise<User> {
    return request<User>({
        url: "/api/userDetails/paymentMethod",
        method: "PUT",
        params: {
            last4: defaultPayment.last4,
            cardBrand: defaultPayment.cardBrand,
            paymentMethodId: defaultPayment.id,
        },
    });
}

export function getPaymentMethods(): Promise<DataResponse<PaymentMethod[]>> {
    return request<DataResponse<PaymentMethod[]>>({
        url: `/api/paymentMethods`,
        method: "GET",
        readOnly: true,
    });
}

export function removePaymentMethod(id: string): Promise<User> {
    return request<User>({
        url: `/api/paymentMethods/${id}`,
        method: "DELETE",
    });
}

export function createShippingAddress(
    shippingAddress: ShippingAddress
): Promise<ShippingAddress> {
    return request<ShippingAddress>({
        url: "/api/shippingAddress",
        method: "POST",
        params: {
            attention: shippingAddress.attention,
            street1: shippingAddress.street1,
            street2: shippingAddress.street2,
            city: shippingAddress.city,
            state: shippingAddress.state,
            zip1: shippingAddress.zip1,
            country: shippingAddress.country,
        },
    });
}

export function getShippingAddresses(): Promise<ShippingAddress[]> {
    return request<ShippingAddress[]>({
        url: `/api/shippingAddress`,
        method: "GET",
        readOnly: true,
    });
}

export function removeShippingAddress(id: string): Promise<{ id: string }> {
    return request<{ id: string }>({
        url: `/api/shippingAddress/${id}`,
        method: "DELETE",
    });
}
