import {
    Analytics,
    logEvent,
    setUserId,
    setUserProperties,
} from "firebase/analytics";
import { FirebaseApp, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { AnalyticsEvent, TrackingOptions } from "types/Analytics";
import { getUntappdUserId } from "helpers/user/userHelpers";

const firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_API_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

export function initializeAnalytics() {
    const app: FirebaseApp = initializeApp(firebaseConfig);
    window.analytics = getAnalytics(app);
}

export function trackEvent(
    eventName: AnalyticsEvent,
    trackingOptions?: TrackingOptions
) {
    const untappdUserId = getUntappdUserId();
    const analytics: Analytics = window.analytics;
    const trackingOptionsWithDefaults: TrackingOptions = {
        ...trackingOptions,
        app_version: process.env.NEXT_PUBLIC_APP_VERSION as string,
        ...(untappdUserId && {
            untappdUserId: untappdUserId,
        }),
    };
    if (!analytics) {
        initializeAnalytics();
    }
    logEvent(
        window.analytics,
        eventName as string,
        trackingOptionsWithDefaults
    );
}

export function identify(userId: number) {
    const analytics: Analytics = window.analytics;
    const untappdUserId = getUntappdUserId();

    if (!analytics) {
        initializeAnalytics();
    }

    setUserId(window.analytics, userId.toString());
    setUserProperties(window.analytics, {
        ...(untappdUserId && {
            untappdUserId: untappdUserId,
        }),
    });
}

export function reset() {
    const analytics: Analytics = window.analytics;

    if (!analytics) {
        initializeAnalytics();
        setUserId(window.analytics, "");
        setUserProperties(window.analytics, {});
    } else {
        setUserId(window.analytics, "");
        setUserProperties(window.analytics, {});
    }
}
